<template>
  <div>
    <div class="sectionButtons-actions">
      <CButton
        color="success"
        size="lg"
        class="sectionButtons-actionsItem"
        @click="openCardNewDriver()"
        >{{$t('new') }} {{ $t('driver') }}</CButton
      >
    </div>
    <CCard accent-color="primary">
      <CCardHeader color="white">
        <b class="text-dark">{{ tableTitle }}</b>
      </CCardHeader>
      <CCardBody>
        <!-- section search -->
        <BlockColumn>
          <BlockItem :size="'1-2'">
            <BlockInfoSelect
              :title="$t('country') + ':'"
              :selected="selectedCountry"
              :values="countryCodes"
              @change-value="changeCountry"
            />
          </BlockItem>
          <BlockItem :size="'1-2'">
            <BlockInfoSelect
              :title="$t('driver') + ' ' + $t('status') + ':'"
              :selected="selectedStatus"
              :values="listStatus"
              @change-value="changeStatus"
            />
          </BlockItem>
        </BlockColumn>

        <!-- <CDataTable
          class="mb-0 table-outline"
          hover
          :items="tableItems"
          :fields="tableFields"
          head-color="light"
          no-sorting
          :items-per-page="10"
          pagination
          table-filter
          items-per-page-select
        > -->
        <CDataTable
          class="mb-0 table-outline"
          hover
          :items="tableItems"
          :fields="tableFields"
          head-color="light"
          external
          :items-per-page-select="{label: $t('items_page')}"
          :items-per-page="perPage"
          :currentPage="currentPage"
          :loading="loading"
          :tableFilter='{ external: true, lazy: true, label:label, placeholder: placeholder_table }'
          @pagination-change="handlePagination"
          @update:table-filter-value ="updateFilter"
        >
          <template #status="{ item }">
            <td>
              <CBadge :color="getAproved(item.approved)">
                {{ getTextAproved(item.status, item.approved) }}
              </CBadge>
            </td>
          </template>
          <template #_rcsSapId="{ item }">
            <td class="text-center">
              {{ item.sapId ? item.sapId : "" }}
            </td>
          </template>
          <template #email="{ item }">
            <td class="text-center">
               {{ item.email ? item.email : "" }}
            </td>
          </template>
            <template #license="{ item }">
            <td class="text-center">
               {{ item.license ? item.license : "" }}
            </td>
          </template>
          <template #_rcsUserName="{ item }">
            <td class="text-center">
              {{ item.rcsUserName ? item.rcsUserName : "" }}
            </td>
          </template>
          <template #options="{ item }">
            <td class="icons">
              <CButton
                size="sm"
                class=""
                v-show="item.status == 3"
                @click="openCardDriverEdit(item)"
              >
                <CIcon name="cil-pencil" />
              </CButton>
                <CButton
                size="sm"
                class=""
                v-show="item.status == 3 && item.approved == 'Approved'"
                @click="resetPassword(item)"
              >
                <CIcon name="cil-shield-alt" />
              </CButton>
              <CButton size="sm" class="" @click="openCardDriverDelete(item)"
              v-show="item.username != 'apple'">
                <CIcon name="cil-trash" />
              </CButton>
            </td>
          </template>
        </CDataTable>
        <CPagination
          :activePage.sync="currentPage"
          :pages="totalPages"
          :active-page="currentPage"
          @update:activePage ="loadData"
        />
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
import BlockColumn from "./BlockColumn";
import BlockItem from "./BlockItem";
import BlockInfoSelect from "./BlockInfoSelect";
export default {
  name: "ListDriver",
  components: {
    BlockColumn,
    BlockItem,
    BlockInfoSelect,
  },
  watch: { 
    countryCodes: function(newVal, oldVal) { // watch it
      
    this.selectedCountry = this.countryCodes[0];
    }
  },
  props: {
    tableItems: Array,
    countryCodes: Array,
    totalPages: Number,
  },
  data() {
    return {
      layout: "list",

      // props table drivers
      tableTitle: this.$i18n.t("drivers"),
      tableFields: [
        { key: "name", label: this.$t('name') },
        { key: "username", label: this.$t('username'), _classes: "Username" },
        { key: "email",label: this.$t('email'), _classes: "text-left" },
        { key: "phoneNumber", label: this.$t('phone_number'), _classes: "text-center" },
        { key: "license", label: this.$t('license'), _classes: "text-center" },
        { key: "_rcsSapId", label: this.$t('sap_id'), _classes: "text-center" },
        { key: "_rcsUserName", label: this.$t('sap_name'), _classes: "text-center" },
        { key: "countryCode", label: this.$t('country') + ' ' + this.$t('code'), _classes: "text-center" },
        { key: "region", label: this.$t('region'), _classes: "text-center" },
        { key: "status", label: this.$t('status') },
        {
          key: "options",
          label: this.$t('options'),
          sorter: false,
          filter: false,
          
        },
      ],
      label: this.$t('search') + ' ' + this.$t('driver')+ ':',
        placeholder_table: this.$i18n.i18next.t('type_string') + ' ↵',
      // tableItems:[],
      listStatus: [],
      selectedCountry: null,
      selectedStatus: null,
      perPage: 10, // Number of items per page
      currentPage: 1, // Current page
      
      tableFilter: '',
      sorter: {
        enabled: true,
      },
      loading: false,
    };
  },
  async created() {
    const self = this;

    self.listStatus = [
      { _key: "0", value: this.$i18n.i18next.t('all') + ' ' + this.$i18n.i18next.t('status') },
      { _key: "1", value: "Approved" },
      { _key: "2", value: "Pending" },
    ];
    this.selectedStatus = this.listStatus[0]._key;
  },
  async mounted() {
    this.loadData()
  },
  methods: {
    updateFilter(value){
      this.tableFilter = value;
      this.$emit("driver-filter", this.getFilterData());
    },
    handlePagination( itemsPerPage ) {
      this.perPage = itemsPerPage;
      this.$emit("driver-filter", this.getFilterData());
    },
    async loadData() {
      this.$emit("driver-filter", this.getFilterData());
    },
    openCardNewDriver() {
      this.$emit("driver-new", {
        countryCodes: this.countryCodes,
        cardTitle: "New driver",
        driverSelected: {},
        showCardDriver: true,
      });
    },
    openCardDriverEdit(driver) {
      this.$emit("driver-edit", {
        showCardDriver: true,
        driverSelected: driver,
        inputsDisabled: true,
        countryCodes: this.countryCodes,
        btnEventText: driver.approved == "Approved" ? "Save" : "Approved",
        btnEventType: "success",
        cardTitle: "Edit driver",
      });
    },
      resetPassword(driver) {
      this.$emit("driver-reset-password", {
        driverSelected: driver,
        
        notificationType: "danger",
        notification: `You are about to reset the password for the driver ${driver.username}`,
      });
    },
    openCardDriverDelete(driver) {
      this.$emit("driver-delete", {
        showCardDriver: true,
        driverSelected: driver,
        inputsDisabled: true,

        notificationType: "danger",
        notification: `You are about to eliminate the driver ${driver.username}`,

        btnEventText: "Delete",
        btnEventType: "danger",
        cardTitle: "Delete driver",
      });
    },
    changeStatus(status) {
      var self = this;
      this.selectedStatus= status;
      
      this.$emit("driver-filter", this.getFilterData());

     
    },
    getFilterData(){
      var self = this;
     var filter= {
            country: self.selectedCountry == this.$t('all_countries') ? null : self.selectedCountry,
            tableFilter: self.tableFilter,
            driverStatus: self.selectedStatus,
            currentPage: self.currentPage,
            perPage:self.perPage
      }
      return filter
    },
    changeCountry(country) {
      this.selectedCountry = country;
      this.$emit("driver-filter", this.getFilterData());

   
    },
    /**
     * Metodo para obtener el tag del aprovado del usuario
     */
    getAproved(status) {
      switch (status) {
        case "Approved":
          return "success";
        case "Pending":
          return "warning";
        default:
          return "warning";
      }
    },

    getTextAproved(status, approved) {
      if (status == 1) {
        return "Validating email";
      }
      if (status == 2) {
        return "Validating sms";
      }
      if (status == 3) {
        return approved;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.icons {
  white-space: nowrap;
  display: inline-block;
}
.sectionButtons-actions {
  padding: 1em 0 2em 1em;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.sectionButtons-actionsItem {
  margin-left: 1em;
  font-size: 14px;
}
</style>