var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"sectionButtons-actions"},[_c('CButton',{staticClass:"sectionButtons-actionsItem",attrs:{"color":"success","size":"lg"},on:{"click":function($event){return _vm.openCardNewDriver()}}},[_vm._v(_vm._s(_vm.$t('new'))+" "+_vm._s(_vm.$t('driver')))])],1),_c('CCard',{attrs:{"accent-color":"primary"}},[_c('CCardHeader',{attrs:{"color":"white"}},[_c('b',{staticClass:"text-dark"},[_vm._v(_vm._s(_vm.tableTitle))])]),_c('CCardBody',[_c('BlockColumn',[_c('BlockItem',{attrs:{"size":'1-2'}},[_c('BlockInfoSelect',{attrs:{"title":_vm.$t('country') + ':',"selected":_vm.selectedCountry,"values":_vm.countryCodes},on:{"change-value":_vm.changeCountry}})],1),_c('BlockItem',{attrs:{"size":'1-2'}},[_c('BlockInfoSelect',{attrs:{"title":_vm.$t('driver') + ' ' + _vm.$t('status') + ':',"selected":_vm.selectedStatus,"values":_vm.listStatus},on:{"change-value":_vm.changeStatus}})],1)],1),_c('CDataTable',{staticClass:"mb-0 table-outline",attrs:{"hover":"","items":_vm.tableItems,"fields":_vm.tableFields,"head-color":"light","external":"","items-per-page-select":{label: _vm.$t('items_page')},"items-per-page":_vm.perPage,"currentPage":_vm.currentPage,"loading":_vm.loading,"tableFilter":{ external: true, lazy: true, label:_vm.label, placeholder: _vm.placeholder_table }},on:{"pagination-change":_vm.handlePagination,"update:table-filter-value":_vm.updateFilter},scopedSlots:_vm._u([{key:"status",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('CBadge',{attrs:{"color":_vm.getAproved(item.approved)}},[_vm._v(" "+_vm._s(_vm.getTextAproved(item.status, item.approved))+" ")])],1)]}},{key:"_rcsSapId",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(item.sapId ? item.sapId : "")+" ")])]}},{key:"email",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(item.email ? item.email : "")+" ")])]}},{key:"license",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(item.license ? item.license : "")+" ")])]}},{key:"_rcsUserName",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(item.rcsUserName ? item.rcsUserName : "")+" ")])]}},{key:"options",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"icons"},[_c('CButton',{directives:[{name:"show",rawName:"v-show",value:(item.status == 3),expression:"item.status == 3"}],attrs:{"size":"sm"},on:{"click":function($event){return _vm.openCardDriverEdit(item)}}},[_c('CIcon',{attrs:{"name":"cil-pencil"}})],1),_c('CButton',{directives:[{name:"show",rawName:"v-show",value:(item.status == 3 && item.approved == 'Approved'),expression:"item.status == 3 && item.approved == 'Approved'"}],attrs:{"size":"sm"},on:{"click":function($event){return _vm.resetPassword(item)}}},[_c('CIcon',{attrs:{"name":"cil-shield-alt"}})],1),_c('CButton',{directives:[{name:"show",rawName:"v-show",value:(item.username != 'apple'),expression:"item.username != 'apple'"}],attrs:{"size":"sm"},on:{"click":function($event){return _vm.openCardDriverDelete(item)}}},[_c('CIcon',{attrs:{"name":"cil-trash"}})],1)],1)]}}])}),_c('CPagination',{attrs:{"activePage":_vm.currentPage,"pages":_vm.totalPages,"active-page":_vm.currentPage},on:{"update:activePage":[function($event){_vm.currentPage=$event},_vm.loadData],"update:active-page":function($event){_vm.currentPage=$event}}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }